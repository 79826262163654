<template>
  <v-app class="wmyHomeApp">
    <v-banner single-line class="banner">
      <v-img
        src="https://h5.ophyer.cn/official_website/other/wmyH5solutionAR.png"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
        
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex mt-8" style="width:100%">
          <v-card color="transparent" flat style="width:100%">
            <div class="d-flex flex-no-wrap justify-space-between" style="width:100%">
              <div style="width:100%">
                <v-card-title class="white--text justify-center py-2 text-align-center font-size-h5banner-title">
                  <!-- 虚拟与现实结合的<br>趣味运营体验 -->
                  虚拟与现实结合的<br>趣味互动体验
                </v-card-title>
                <v-card-title class="white--text font-size-h5banner-tip justify-center py-1 text-align-center px-5">
                    <!-- 海量模板+趣味运营玩法<br>助力企业指数级提升运营指标 -->
                    海量模板+趣味互动玩法<br>助力企业指数级提升运营指标
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- AR互动游戏 -->
    <div class="width-per-100 pb-3 px-4 mt-5">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">{{data.module.title}}</v-card-title>
      <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">{{data.module.subtitle}}</v-card-text>
      <v-tabs v-model="tab" background-color="transparent" color="#2181EA" dark class="xs-tab" style="margin-top:0">
        <v-tab v-for="(v, k) in data.module.listArr" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === data.module.listArr.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class="color-666 font-size-14 letter-spacing line-height-20">{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-1 mx-1 position-r" v-model="tab" style="border-radius: 10px;">
        <v-tab-item v-for="(v, k) in data.module.listArr" :key="k" :transition="false">
          <v-card flat color="white" class="pa-5">
            <v-img :src="v.img" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 font-weight-bold text-align-center line-height-25 white-space-pre-wrap justify-center">{{v.lunTitle}}</v-card-title>
            <v-card-text class="pa-0 font-size-12 color-555 line-height-21 text-align-center" style="margin-top: 6px;" v-text="v.tip" />
          </v-card>
        </v-tab-item>
        <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
        <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
      </v-tabs-items>
    </div>
    <v-card class="pb-6" flat color="transparent">
      <div class="mt-1">
        <v-row v-for="(row,rowIndex) in data.module1" :key="rowIndex" class="pa-0">
          <v-card :class="['py-5 px-3 d-flex h5-card',rowIndex % 2 == 0 ?'':'justify-flex-end']" width="100%" height="100%" flat >
            <v-col cols="4" v-if="rowIndex % 2 == 0" class="pa-0 d-flex align-center">
                <v-img class="flex-grow-0" :src="row.icon" max-width="100%" contain position="top"></v-img>
            </v-col>
            <v-col cols="8" class="pa-0">
                <div :class="[rowIndex % 2 == 0 ? 'ml-4':'mr-4']">
                <v-card-title class="pa-0 mt-2 font-size-15 font-weight-bold white-space-pre-wrap" v-text="row.title"></v-card-title>
                <v-card-text class="pa-0 mt-2 font-size-12 color-666" style="line-height:15px">
                    {{row.explain}}
                </v-card-text>
                </div>
            </v-col>
            <v-col cols="4" v-if="rowIndex % 2 != 0" class="pa-0 d-flex align-center">
                <v-img class="flex-grow-0" :src="row.icon" contain position="top" max-width="100%" ></v-img>
            </v-col>
          </v-card>
        </v-row>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import lavData from '@/static/lavData.js'
export default {
  name: 'solutionAR',
  components: {
  },
  data() {
    return {
      tab:0,
      data:lavData.h5SolutionAR,
      imgLoad: false,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleLoad() {
      this.imgLoad = true;
    },
    handleTabBtn: function (type){
      const maxTab = this.data.module.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
}
.v-tab--active{
    b{
        color: #2181EA !important;
    }
}
.justify-flex-end{
    justify-content: flex-end;
}
.v-window{
  overflow: unset;
}
.tab-btn-prev{
  left:-19px;
}
.tab-btn-next{
  right:-19px
}
.wmyHomeApp{
  background: #F5F8FE;
}
</style>
